import ACCOUNT_ACTION_TYPES, {
  ACCOUNT_SECURITY_MFA_ACTIVE_TAB,
  AntiPhishingActionType,
  BankDetailsActionType,
  DepositsActionTypeIndia,
  FiatBankDetailsActionTypeIndia,
  KycImportActionType,
  SignalTradingActionType,
  WithdrawalActionTypeIndia,
  WithdrawalHistoryActionType,
} from 'actionTypes/account';
import {
  ACCOUNTS_API,
  BANK_DETAILS,
  DEPOSITS_INDIA,
  FIAT_BANK_DETAILS_INDIA,
  SIGNAL_API,
  USER_PROFILE,
  WITHDRAWAL_INDIA,
} from 'constants/api';
import { TRANSACTION_PARTNER_NAMES } from 'constants/enums';
import createAction from 'helpers/createAction';
import { AddBankCustodianStatus } from 'types/Account';
import type {
  ClaimDepositPayloadData,
  CreateWebhookPayload,
  DeleteWebhookPayload,
  FiatWithdrawalHistoryPayload,
  GetRefundHistoryPayload,
  SubmitRefundDetailsPayloadData,
} from 'types/actions/account';
import { type CursorPaginationParams, type PagePaginationParams } from 'types/General';
import { CustomAction } from 'types/Istore';

import { authAction } from './user';

// #region Anti-Phishing Code
const openChangeCodeModal = createAction(AntiPhishingActionType.OPEN_CHANGE_CODE_MODAL);

const triggerAntiPhishingVerification = createAction(
  AntiPhishingActionType.TRIGGER_VERIFICATION
);

const resendAntiPhishingVerification = createAction(
  AntiPhishingActionType.RESEND_EMAIL_CODE
);

const triggerVerificationFailed = createAction(
  AntiPhishingActionType.TRIGGER_VERIFICATION_FAILED
);

const verifyIdentityForAntiPhishing = createAction(
  AntiPhishingActionType.VERIFY_IDENTITY
);

const resetAntiPhishing = createAction(AntiPhishingActionType.RESET);

function sendAntiPhishingVerificationCode(recaptchaToken: string): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[AntiPhishingActionType.SEND_VERIFICATION_CODE],
    promise: ({ request }) =>
      request.post(USER_PROFILE.ANTI_PHISHING.SEND_VERIFICATION_CODE, {
        data: { recaptcha_response: recaptchaToken },
      }),
  };
}

function updateAntiPhishingCode(
  phishingCode: string,
  emailCode: string,
  mfaCode?: string
): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[AntiPhishingActionType.UPDATE_CODE],
    promise: ({ request }) =>
      request.post(USER_PROFILE.ANTI_PHISHING.UPDATE_CODE, {
        data: {
          phishing_code: phishingCode,
          email_code: emailCode,
          mfa_code: mfaCode,
        },
      }),
  };
}
// #endregion

// #region  FiatWithdrawalHistory
function fiatWithdrawalHistory(payload: FiatWithdrawalHistoryPayload) {
  return authAction({
    types: ACCOUNT_ACTION_TYPES[WithdrawalHistoryActionType.FETCH_DATA],
    promise: ({ request }) =>
      request.get(ACCOUNTS_API.FIAT_WITHDRAWAL_HISTORY, {
        params: {
          ...payload,
        },
      }),
  });
}
// #endregion

// #region Bank Details
function getBankAccounts(status?: AddBankCustodianStatus): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[BankDetailsActionType.GET_BANK_ACCOUNTS],
    promise: ({ request }) => {
      return request.get(BANK_DETAILS.LIST.GET, {
        params: { status },
      });
    },
  };
}

function sendOtpToAddBankAccount({
  accountNumber,
  ifscCode,
  label,
}: {
  accountNumber: string;
  ifscCode: string;
  label: string;
}): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[BankDetailsActionType.ADD_ACCOUNT_SEND_OTP],
    promise: ({ request }) => {
      return request.post(BANK_DETAILS.ADD_ACCOUNT.SEND_OTP, {
        data: {
          account_number: accountNumber,
          ifsc_code: ifscCode,
          customized_bank_name: label,
        },
      });
    },
  };
}

function resendOtpToAddBankAccount(uuid: string): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[BankDetailsActionType.ADD_ACCOUNT_RESEND_OTP],
    promise: ({ request }) => {
      return request.post(BANK_DETAILS.ADD_ACCOUNT.RESEND_OTP, {
        data: { uuid },
      });
    },
  };
}

function verifyOtpToAddBankAccount({
  uuid,
  emailCode,
}: {
  uuid: string;
  emailCode: string;
}): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[BankDetailsActionType.ADD_ACCOUNT_VERIFY_OTP],
    promise: ({ request }) => {
      return request.post(BANK_DETAILS.ADD_ACCOUNT.VERIFY_OTP, {
        data: {
          uuid,
          otp: emailCode,
        },
      });
    },
  };
}

function checkStatusOfAddedBankAccount(requestId: string) {
  return {
    types: ACCOUNT_ACTION_TYPES[BankDetailsActionType.ADD_ACCOUNT_CHECK_STATUS],
    promise: ({ request }) => {
      return request.get(BANK_DETAILS.ADD_ACCOUNT.CHECK_STATUS, {
        params: { request_id: requestId },
      });
    },
  };
}

function deleteBankAccount(userBankDetailId: number) {
  return {
    types: ACCOUNT_ACTION_TYPES[BankDetailsActionType.DELETE_BANK_ACCOUNT],
    promise: ({ request }) => {
      return request.post(BANK_DETAILS.DELETE_BANK_ACCOUNT, {
        data: { user_bank_detail_id: userBankDetailId },
      });
    },
  };
}

function getValidIFSCCodes() {
  return {
    types: ACCOUNT_ACTION_TYPES[BankDetailsActionType.GET_BANK_DETAILS],
    promise: ({ request }) => {
      return request.get(BANK_DETAILS.GET_BANK_DETAILS);
    },
  };
}
// #endregion

function getFiatBankDetailsIndia(status?: AddBankCustodianStatus): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[FiatBankDetailsActionTypeIndia.GET_FIAT_BANK_DETAILS],
    promise: ({ request }) => {
      return request.get(FIAT_BANK_DETAILS_INDIA.GET, {
        params: { status },
      });
    },
  };
}

// #region Deposits India
interface PostInitiateDepositIndiaPayload {
  // transferAmt: number;
  transferMode: string;
  userBankDetailsId: number;
  transactionPartnerName?: string;
}

interface PostConfirmDepositIndiaPayload {
  fromBankId: number;
  virtualAccountNumber: string;
  virtualAccountIfscCode: string;
  amount: number;
  method: string;
  transactionPartnerName?: string;
  utrNumber?: string;
}

function postInitiateDepositIndia({
  // transferAmt,
  transferMode,
  userBankDetailsId,
  transactionPartnerName,
}: PostInitiateDepositIndiaPayload): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.NEW_DEPOSITS],
    promise: ({ request }) => {
      return request.post(DEPOSITS_INDIA.NEW, {
        data: {
          // transfer_amt: transferAmt,
          transfer_mode: transferMode,
          user_bank_details_id: userBankDetailsId,
          tx_partner_name: transactionPartnerName,
        },
      });
    },
  };
}

function getDepositHistoryIndia({
  page,
  limit,
}: PagePaginationParams = {}): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.GET_DEPOSIT_HISTORY],
    promise: ({ request }) => {
      return request.get(DEPOSITS_INDIA.HISTORY.GET, {
        params: {
          page,
          size: limit,
        },
      });
    },
  };
}

function postConfirmDepositIndia(payload: PostConfirmDepositIndiaPayload): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.CONFIRM_DEPOSITS],
    promise: ({ request }) => {
      const data = {
        user_bank_details_id: payload.fromBankId,
        transfer_amt: payload.amount,
        transfer_type: payload.method,
        tx_partner_name: payload.transactionPartnerName,
      };

      if (payload.transactionPartnerName === TRANSACTION_PARTNER_NAMES.DE_INTERNAL) {
        data.transfer_unique_no = payload.utrNumber;
      }

      return request.post(DEPOSITS_INDIA.CONFIRM, {
        data,
      });
    },
  };
}

const refetchDepositHistoryIndia = createAction(
  DepositsActionTypeIndia.REFETCH_DEPOSIT_HISTORY
);
// #endregion

// #region Withdrawal India
interface PostInitiateWithdrawalIndiaPayload {
  fiatAmount: string;
}

interface PostCodeVerificationforWithdrawalIndiaPayload {
  fiatAmount: string;
  userBankDetailId: number;
  otp: string;
  mfaCode: string;
  uid: string;
}

// interface PostConfirmDepositIndiaPayload {
//   fromBankId: number;
//   virtualAccountNumber: string;
//   virtualAccountIfscCode: string;
//   amount: number;
//   method: string;
// }

function postInitiateWithdrawalIndia({
  fiatAmount,
}: PostInitiateWithdrawalIndiaPayload): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[WithdrawalActionTypeIndia.NEW_WITHDRAWAL],
    promise: ({ request }) => {
      return request.post(WITHDRAWAL_INDIA.NEW, {
        data: {
          fiat_amount: fiatAmount,
          asset_symbol: 'USD',
          custodian: 'neokred',
          exchange_type: 'local',
          fiat_asset: 'INR',
        },
      });
    },
  };
}

function postCodeVerificationforWithdrawalIndia({
  fiatAmount,
  userBankDetailId,
  otp,
  mfaCode,
  uid,
}: PostCodeVerificationforWithdrawalIndiaPayload): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[WithdrawalActionTypeIndia.WITHDRAWAL_CODE_VERIFICATION],
    promise: ({ request }) => {
      return request.post(WITHDRAWAL_INDIA.CODE_VERIFICATION, {
        data: {
          fiat_amount: fiatAmount,
          user_bank_detail_id: userBankDetailId,
          otp,
          mfa_code: mfaCode,
          uid,

          asset_symbol: 'USD',
          custodian: 'neokred',
          exchange_type: 'local',
          payment_method: 'IMPS',
          fiat_asset: 'INR',
        },
      });
    },
  };
}

function getWithdrawalHistoryIndia({
  before,
  after,
  limit,
}: CursorPaginationParams = {}): CustomAction {
  return {
    types: ACCOUNT_ACTION_TYPES[WithdrawalActionTypeIndia.GET_WITHDRAWAL_HISTORY],
    promise: ({ request }) => {
      return request.get(WITHDRAWAL_INDIA.HISTORY.GET, {
        params: {
          withdrawal_type: 'fiat',
          before,
          after,
          page_size: limit,
        },
      });
    },
  };
}

const refetchWithdrawalHistoryIndia = createAction(
  WithdrawalActionTypeIndia.REFETCH_WITHDRAWAL_HISTORY
);
// #endregion

function getUserKycStatusFromGlobal() {
  return {
    types: ACCOUNT_ACTION_TYPES[KycImportActionType.GET_KYC_STATUS_FROM_GLOBAL],
    promise: ({ request }) => request.get(USER_PROFILE.GET_KYC_VERIFICATION_STATUS),
  };
}

function importKycStatusFromGlobal(stateOfResidence: string) {
  return {
    types: ACCOUNT_ACTION_TYPES[KycImportActionType.IMPORT_KYC_FROM_GLOBAL],
    promise: ({ request }) =>
      request.post(USER_PROFILE.KYC_IMPORT_GLOBAL, {
        data: {
          region: stateOfResidence,
        },
      }),
  };
}

// #region signalTrading

function sendSignalVerificationCodeRequest() {
  return authAction({
    fetch_main_acc_token: true,
    types: ACCOUNT_ACTION_TYPES[SignalTradingActionType.SEND_VERIFICATION_CODE],
    promise: ({ request }) => request.post(SIGNAL_API.SEND_VERIFICATION_CODE),
  });
}

function createWebHook(payload: CreateWebhookPayload, fetchMainAccountToken: boolean) {
  return authAction({
    fetch_main_acc_token: fetchMainAccountToken,
    types: ACCOUNT_ACTION_TYPES[SignalTradingActionType.CREATE_WEBHOOK],
    promise: ({ request }) => request.post(SIGNAL_API.WEBHOOK, payload),
  });
}

function deleteWebook(payload: DeleteWebhookPayload) {
  return authAction({
    types: ACCOUNT_ACTION_TYPES[SignalTradingActionType.DELETE_WEBHOOK],
    promise: ({ request }) => request.delete(SIGNAL_API.WEBHOOK, payload),
  });
}
// #endregion

function getActiveWebhooks() {
  return authAction({
    types: ACCOUNT_ACTION_TYPES[SignalTradingActionType.GET_ACTIVE_WEBHOOKS],
    promise: ({ request }) => request.get(SIGNAL_API.GET_WEBHOOKS),
  });
}

const setActiveMfaTab = createAction(ACCOUNT_SECURITY_MFA_ACTIVE_TAB);

const claimDeposit = ({ utrNumber, depositPartner }: ClaimDepositPayloadData) => {
  return authAction({
    types: ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.CLAIM_DEPOSIT],
    promise: ({ request }) =>
      request.post(DEPOSITS_INDIA.CLAIM_DEPOSIT, {
        data: {
          transfer_unique_no: utrNumber,
          tx_partner_name: depositPartner,
        },
      }),
  });
};

const submitRefundDetails = ({
  accountNumber,
  ifscCode,
  utrNumber,
  bankStatementFile,
}: SubmitRefundDetailsPayloadData) => {
  const formData = new FormData();
  const payloadData = {
    account_number: accountNumber,
    ifsc_code: ifscCode,
    transfer_unique_no: utrNumber,
    file: bankStatementFile,
  };
  Object.entries(payloadData).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return authAction({
    types: ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.SUBMIT_REFUND_DETAILS],
    promise: ({ request }) =>
      request.post(DEPOSITS_INDIA.SUBMIT_REFUND_DETAILS, {
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
  });
};

const getRefundHistoryIndia = (params: GetRefundHistoryPayload) => {
  return authAction({
    types: ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.GET_REFUND_DETAILS],
    promise: ({ request }) =>
      request.get(DEPOSITS_INDIA.GET_USER_REFUND_DETAILS, {
        params,
      }),
  });
};

export {
  checkStatusOfAddedBankAccount,
  createWebHook,
  deleteBankAccount,
  deleteWebook,
  fiatWithdrawalHistory,
  getActiveWebhooks,
  getBankAccounts,
  getDepositHistoryIndia,
  getFiatBankDetailsIndia,
  getUserKycStatusFromGlobal,
  getValidIFSCCodes,
  getWithdrawalHistoryIndia,
  importKycStatusFromGlobal,
  openChangeCodeModal,
  postCodeVerificationforWithdrawalIndia,
  postConfirmDepositIndia,
  postInitiateDepositIndia,
  postInitiateWithdrawalIndia,
  refetchDepositHistoryIndia,
  refetchWithdrawalHistoryIndia,
  resendAntiPhishingVerification,
  resendOtpToAddBankAccount,
  resetAntiPhishing,
  sendAntiPhishingVerificationCode,
  sendOtpToAddBankAccount,
  sendSignalVerificationCodeRequest,
  setActiveMfaTab,
  triggerAntiPhishingVerification,
  triggerVerificationFailed,
  updateAntiPhishingCode,
  verifyIdentityForAntiPhishing,
  verifyOtpToAddBankAccount,
  claimDeposit,
  submitRefundDetails,
  getRefundHistoryIndia,
};
