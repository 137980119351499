import { IS_INDIAN_EXCHANGE } from './constants';

const SIGNAL_TRADING_FEATURE_ENABLED =
  process.env.REACT_APP_FEATURE_FLAG_SIGNAL_TRADING === 'true';

const SIGNUP_CARDS_FLAG = IS_INDIAN_EXCHANGE ? 'india' : 'global';

const SHOW_DELTA_INDIA_LOGIN_LINK = !IS_INDIAN_EXCHANGE;

const TRADE_PREFERENCES_DETO_FOR_COMISSION = !IS_INDIAN_EXCHANGE;

const INVOICES_TAB = IS_INDIAN_EXCHANGE;

const ENABLE_INR_TOGGLE_IN_ACCOUNT_ANALYTICS = IS_INDIAN_EXCHANGE;

const RECOVER_USER_ACCOUNT_FEATURE_ENABLED = IS_INDIAN_EXCHANGE;

const SHOW_DELTA_INDIA_ANALYTICS_REDIRECTION_LINK = !IS_INDIAN_EXCHANGE;

const SHOW_DELTA_GLOBAL_BANK_WITHDRAWAL_PAGE = false;

const ENABLE_TRACK_DEPOSIT = IS_INDIAN_EXCHANGE;

export {
  SIGNAL_TRADING_FEATURE_ENABLED,
  SHOW_DELTA_INDIA_LOGIN_LINK,
  SIGNUP_CARDS_FLAG,
  TRADE_PREFERENCES_DETO_FOR_COMISSION,
  INVOICES_TAB,
  ENABLE_INR_TOGGLE_IN_ACCOUNT_ANALYTICS,
  RECOVER_USER_ACCOUNT_FEATURE_ENABLED,
  SHOW_DELTA_INDIA_ANALYTICS_REDIRECTION_LINK,
  SHOW_DELTA_GLOBAL_BANK_WITHDRAWAL_PAGE,
  ENABLE_TRACK_DEPOSIT,
};
