import { URL_SEARCH_PARAMS_KEYS } from './urlSearchParams';

const { HOMEPAGE: HOMEPAGE_SEARCH_PARAMS } = URL_SEARCH_PARAMS_KEYS;

const HOMEPAGE_TABS_MAPPING = {
  OPTIONS_CHAIN: 'options_chain',
  FUTURES: 'futures',
  LEADERBOARD: 'leaderboard',
  OPTIONS_COMBOS: 'options_combos',
} as const;

const getStringifiedSearchParams = (
  arg: ConstructorParameters<typeof URLSearchParams>[0]
) => `?${new URLSearchParams(arg).toString()}`;

const base = '';

const main = {
  app: base,
  account: `${base}/account`,
  easy_options: `${base}/easy_options`,
  robo_strategies: `${base}/robo-strategies`,
  favorites: `${base}/favorites`,
  futures: `${base}/futures`,
  irs: `${base}/interest_rate_swaps`,
  options: `${base}/options`,
  turbo: `${base}/turbo_options`,
  move_options: `${base}/move_options`,
  options_chain: `${base}/options_chain`,
  spreads: `${base}/spreads`,
  spot: `${base}/spot`,
  optionsCombos: `${base}/options_combos`,
  transactionLog: `${base}/account/transaction_logs`,
  security: `${base}/account/security`,
  positions: `${base}/account/positions`,
  accountAnalytics: `${base}/account/account_analytics`,
  basketOrder: `${base}/basketOrder`,
  optionsAnalyticsDashboard: `${base}/options_analytics`,
  homepage: `${base}/home`,
};

export const routes = {
  default: `${main.app}/`,
  embedChart: `${main.app}/tradingview/:chartType/:contractType/:assetSymbol/:productSymbol`,
  maintenance: `${main.app}/maintenance`,
  resources: `${main.app}/settings`,
  trollbox: `${main.app}/trollbox`,
  // iconList: `${main.app}/icons`,
  usefulResources: `${main.app}/useful_resources`,
  convert: `${main.app}/convert`,
  portfolio: `${main.app}/portfolio`,
  customLeaderBoard: `${main.app}/:product/leaderboard`,
  optionsLeaderboard: `${main.app}/options/leaderboard`,
  recoverDeleteAccount: `${main.app}/recover_deleted_account`,
  account: {
    default: main.account,
    my_account: `${main.account}/my_account`,
    profile: `${main.account}/profile`,
    subAccounts: `${main.account}/subaccounts`,

    identityVerification: `${main.account}/identityVerification`,
    manageapikeys: `${main.account}/manageapikeys`,
    createapikeys: `${main.account}/manageapikeys/create`,
    balances: `${main.account}/balances`,
    positions: `${main.account}/positions`,
    portfolioMargin: `${main.account}/portfolio`,
    withdrawal_bank_history: `${main.account}/fiat_withdrawal_history`,
    // positions: {
    //   default: main.positions,
    //   all_positions: main.positions + '/all_positions',
    //   options_positions: main.positions + '/options_positions',
    // },
    security: {
      default: main.security,
      account_activity: `${main.security}/account_activity`,
      twofa: {
        enable_2fa: `${main.security}/settings_2fa`,
        disable_2fa: `${main.security}/settings_disable_2fa`,
      },
      change_password: `${main.security}/change_password`,
      address_management: `${main.security}/address_management`,
    },
    deposit: `${main.account}/deposit`,
    deposit_history: `${main.account}/deposit_history`,
    trackDeposit: `${main.account}/track_deposit`,
    buy_crypto: `${main.account}/buy_crypto`,
    fiat_deposit_history: `${main.account}/fiat_deposit_history`,
    bankDetails: `${main.account}/bank_details`,
    withdrawal: `${main.account}/withdrawal`,
    withdrawalBank: `${main.account}/withdrawal_bank`,
    withdrawal_history: `${main.account}/withdrawal_history`,
    withdrawal_confirmation: `${main.account}/withdrawal/confirm`,
    bots: `${main.account}/bots`,
    preferences: `${main.account}/preferences`,
    transactionLog: {
      default: main.transactionLog,
      asset_history: `${main.transactionLog}/asset_history`,
      order_history: `${main.transactionLog}/order_history`,
      trade_history: `${main.transactionLog}/trade_history`,
      transfer_history: `${main.transactionLog}/transfer_history`,
      invoices: `${main.transactionLog}/invoices`,
    },
    accountAnalytics: {
      default: main.accountAnalytics,
    },
    signalTrading: `${main.account}/signal_trading`,
    signalTradingInfo: `${main.account}/signal_trading_info`,
    activeWebhooks: `${main.account}/active_webhooks`,
  },
  referral: `${main.app}/referral`,
  contracts: `${main.app}/contracts`,
  references: `${main.app}/references`,
  api: `${main.app}/api`,
  login: `${main.app}/login`,
  linkAccount: `${main.app}/link-account`,
  disable2fa: `${main.app}/disable2fa`,
  signup: `${main.app}/signup`,
  verifyRegister: `${main.app}/register/verify`,
  forgotPassword: `${main.app}/forgot_password`,
  resetPassword: `${main.app}/reset_password`,
  updatePassword: `${main.app}/update_password`,
  offers: `${main.app}/offers`,
  expired_futures: `${main.app}/expired_futures`, // only used for redirection to new url
  expired_futures_and_options: `${main.app}/expired_futures_and_options`,
  expired_charts: `${main.app}/expired_charts`, // tv charts for expired products
  easy_options: {
    default: main.easy_options,
    trade: `${main.easy_options}/trade`,
  },
  irs: {
    markets: `${main.irs}/markets`,
    trade: `${main.irs}/trade`,
  },
  favorites: {
    markets: `${main.favorites}/markets`,
  },
  robo_strategies: {
    markets: `${main.robo_strategies}/markets`,
  },
  futures: {
    markets: `${main.futures}/markets`,
    trade: `${main.futures}/trade`,
  },
  options: {
    markets: `${main.options}/markets`,
    trade: `${main.options}/trade`,
  },
  turbo: {
    markets: `${main.turbo}/markets`,
    trade: `${main.turbo}/trade`,
  },
  move_options: {
    markets: `${main.move_options}/markets`,
    trade: `${main.move_options}/trade`,
  },
  spreads: {
    markets: `${main.spreads}/markets`,
    trade: `${main.spreads}/trade`,
  },
  options_chain: {
    markets: `${main.options_chain}/markets`,
    trade: `${main.options_chain}/trade`,
  },
  optionsCombos: {
    markets: `${main.optionsCombos}/markets`,
    trade: `${main.optionsCombos}/trade`,
  },
  spot: {
    markets: `${main.spot}/markets`,
    trade: `${main.spot}/trade`,
  },
  basketOrder: {
    trade: `${main.basketOrder}/trade`,
  },
  optionAnalytics: {
    markets: `${main.optionsAnalyticsDashboard}/markets`,
  },
  crypto_trading_league: `${main.app}/crypto_trading_league`,
  lend_trading_competition: `${main.app}/lend_trading_competition`,
  tomo_trading_competition: `${main.app}/tomo_trading_competition`,
  knc_trading_competition: `${main.app}/knc_trading_competition`,
  enj_trading_competition: `${main.app}/enj_trading_competition`,
  xem_trading_competition: `${main.app}/xem_trading_competition`,
  tamil_trading_competition: `${main.app}/tamilbtc_trading_competition`,
  deto_trading_competition: `${main.app}/deto_trading_competition`,
  bell_alerts: `${main.app}/bell_alerts`,
  futures_trading_competition: `${main.app}/futures_grand_challenge`,
  universalSearchMobile: `${main.app}/universal_search`,
  webAppHome: `${main.app}/futures/markets`,
  optionsAnalyticsDashboard: {
    default: main.optionsAnalyticsDashboard,
  },
  homepage: {
    default: main.homepage,
    optionsChain: `${main.homepage}${getStringifiedSearchParams({
      [HOMEPAGE_SEARCH_PARAMS.TABLE_SECTION_TABS]: HOMEPAGE_TABS_MAPPING.OPTIONS_CHAIN,
    })}`,
    futures: `${main.homepage}${getStringifiedSearchParams({
      [HOMEPAGE_SEARCH_PARAMS.TABLE_SECTION_TABS]: HOMEPAGE_TABS_MAPPING.FUTURES,
    })}`,
    leaderboard: `${main.homepage}${getStringifiedSearchParams({
      [HOMEPAGE_SEARCH_PARAMS.TABLE_SECTION_TABS]: HOMEPAGE_TABS_MAPPING.LEADERBOARD,
    })}`,
    optionsCombos: `${main.homepage}${getStringifiedSearchParams({
      [HOMEPAGE_SEARCH_PARAMS.TABLE_SECTION_TABS]: HOMEPAGE_TABS_MAPPING.OPTIONS_COMBOS,
    })}`,
  },
  verifyEmailWithLink: `${main.app}/verify_email_with_link`,
  logo: `${main.app}/logo/:type`,
  chartingLibrary: `${main.app}/charting_library`,
  balanceCoin: `${main.app}/balanceCoin`,
  tradeHistory: `${main.app}/trade_history`,
  riskViewAll: `${main.app}/risk_view/:asset_symbol`,
};

export { HOMEPAGE_TABS_MAPPING };
export default routes;
